import React, { FC, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import characteristicStore from 'store/characteristicsStore';

import { ICharacteristicTypeShort, ICharacteristicValue } from 'types/types';
import { observer } from 'mobx-react-lite';
import Editor from 'components/editor/editor';
import { Switch } from '@mui/material';

interface IStepInterfaceProps {
  activeStep: number;
  handleBack(): void;
  handleNext(): void;
  handleReset(): void;
}

const boxStyles = {
  display: 'flex',
  flexDirection: 'row',
  pt: 2
};

const typographyStyles = {
  mt: 3,
  mb: 1,
  px: 1
};

const StepContent: FC<IStepInterfaceProps> = ({
  activeStep,
  handleBack,
  handleNext,
  handleReset
}) => {
  const [typeOpen, setTypeOpen] = useState<boolean>(false);
  const [typeLoading, setTypeLoading] = useState<boolean>(false);
  const [valueOpen, setValueOpen] = useState<boolean>(false);
  const [valueLoading, setValueLoading] = useState<boolean>(false);
  const [typeOptions, setTypeOptions] = useState<
    Array<ICharacteristicTypeShort>
  >([]);
  const [valuesOptions, setValuesOptions] = useState<
    Array<ICharacteristicValue>
  >([]);

  const [typeValue, setTypeValue] = useState<ICharacteristicTypeShort | null>(
    null
  );
  const [valueValue, setValueValue] = useState<ICharacteristicValue | null>(
    null
  );

  const [slug, setSlug] = useState<string>('');
  const [metaH1, setMetaH1] = useState<string>('');
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [textContent, setTextContent] = useState<string>('');
  const [switcher, setSwitcher] = useState<boolean>(false);

  useEffect(() => {
    if (typeOpen) {
      setTypeLoading(true);
      characteristicStore.getCharacteristicTypes().then((val) => {
        if (val.isOk) {
          setTypeOptions(val.data);
        }
        setTypeLoading(false);
      });
    }
  }, [typeOpen]);

  useEffect(() => {
    if (valueOpen && typeValue?.id) {
      setValueLoading(true);
      characteristicStore.getCharacteristicType(typeValue.id).then((val) => {
        if (val.isOk) {
          setValuesOptions(val.data);
        }

        setValueLoading(false);
      });
    }
  }, [valueOpen, typeValue]);

  useEffect(() => {
    setValuesOptions([]);
    setValueValue(null);
  }, [typeValue]);

  useEffect(() => {
    if (valueValue) {
      setSlug(valueValue.slug ?? '');
      setTextContent(valueValue.text_content ?? '');
      setMetaH1(valueValue.custom_h1 ?? '');
      setMetaTitle(valueValue.custom_title ?? '');
      setMetaDescription(valueValue.custom_description ?? '');
    }
  }, [valueValue]);

  const handleSave = useCallback(() => {
    if (valueValue?.id) {
      characteristicStore
        .updateCharacteristic(valueValue.id, {
          id: valueValue.id,
          slug,
          text_content: textContent,
          custom_h1: metaH1,
          custom_title: metaTitle,
          custom_description: metaDescription
        })
        .then((res) => {
          if (res.isOk) {
            handleNext();
          }
        });
    }
  }, [
    handleNext,
    typeValue,
    valueValue,
    slug,
    textContent,
    metaH1,
    metaTitle,
    metaDescription
  ]);

  return (
    <>
      <Box sx={typographyStyles}>
        {activeStep === 0 && (
          <Autocomplete
            id="asynchronous-types"
            fullWidth
            value={typeValue}
            onChange={(e, val) => setTypeValue(val)}
            open={typeOpen}
            onOpen={() => {
              setTypeOpen(true);
            }}
            onClose={() => {
              setTypeOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={typeOptions}
            loading={typeLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Тип характеристик"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {typeLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        {activeStep === 1 && (
          <Autocomplete
            id="asynchronous-types"
            fullWidth
            value={valueValue}
            onChange={(e, val) => setValueValue(val)}
            open={valueOpen}
            onOpen={() => {
              setValueOpen(true);
            }}
            onClose={() => {
              setValueOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.value}
            options={valuesOptions}
            loading={valueLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Значение характеристики"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {valueLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        {activeStep === 2 && (
          <>
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
              <Typography sx={{ width: '20%' }}>ЧПУ</Typography>
              <TextField
                value={slug}
                size="small"
                onChange={(e) => setSlug(e.currentTarget.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', pt: 3 }}>
              <Typography sx={{ width: '20%' }}>H1</Typography>
              <TextField
                value={metaH1}
                size="small"
                onChange={(e) => setMetaH1(e.currentTarget.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', pt: 3 }}>
              <Typography sx={{ width: '20%' }}>Мета Title</Typography>
              <TextField
                value={metaTitle}
                size="small"
                onChange={(e) => setMetaTitle(e.currentTarget.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', pt: 3 }}>
              <Typography sx={{ width: '20%' }}>Мета Description</Typography>
              <TextField
                value={metaDescription}
                size="small"
                onChange={(e) => setMetaDescription(e.currentTarget.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ pt: 3 }}>
              <Switch
                checked={switcher}
                onChange={() => {
                  setSwitcher((oldVal) => !oldVal);
                }}
                inputProps={{ 'aria-label': 'controlled-switcher' }}
              />

              {switcher ? (
                <Editor
                  notifyUser={(mes, _status) => console.log(mes)}
                  value={textContent}
                  onEditorChange={(val) => setTextContent(val)}
                  id="tinymce-content-editor"
                />
              ) : (
                <TextField
                  fullWidth
                  multiline
                  minRows={10}
                  value={textContent}
                  onChange={(val) => {
                    setTextContent(val.target.value);
                  }}
                />
              )}
            </Box>
          </>
        )}
        {activeStep === 3 && (
          <Typography sx={{ mt: 2, mb: 1 }}>
            Изменения успешно сохранены
          </Typography>
        )}
      </Box>
      <Box sx={boxStyles}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Назад
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {(activeStep === 0 || activeStep === 1) && (
          <Button
            onClick={handleNext}
            disabled={
              (activeStep === 1 && !valueValue) ||
              (activeStep === 0 && !typeValue)
            }
          >
            Далее
          </Button>
        )}
        {activeStep === 2 && <Button onClick={handleSave}>Сохранить</Button>}
        {activeStep === 3 && <Button onClick={handleReset}>Заново</Button>}
      </Box>
    </>
  );
};

export default observer(StepContent);
