import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  Button,
  Stack,
  Switch
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Editor from 'components/editor/editor';
import Loader from 'components/loader';
import Notification from 'components/notification';

import seoStore from 'store/seoStore';
import globalState from 'store/globalState';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            pt: 3
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const SeoTag: FC = () => {
  const { id } = useParams();
  const { seo, loading } = seoStore;
  const [switchChecked, setSwitchChecked] = useState<boolean>(
    !seo.text?.includes('<!-- widget -->')
  );
  const [tabPanel, setTabPanel] = useState<number>(0);
  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);

  const [url, setUrl] = useState<string>(seo.url || '');
  const [metaH1, setMetaH1] = useState<string>(seo.meta_h1 || '');
  const [metaTitle, setMetaTitle] = useState<string>(seo.meta_title || '');
  const [metaDescription, setMetaDescription] = useState<string>(
    seo.meta_description || ''
  );
  const [metaKeywords, setMetaKeywords] = useState<string>(
    seo.meta_keywords || ''
  );
  const [text, setText] = useState<string>(seo.text || '');

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationMsg, setNotificationMsg] = useState<string>('');
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'info' | 'warning'
  >('error');

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success' | 'info' | 'warning') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  useEffect(() => {
    globalState.setNewPathname('Страница Seo');

    if (id) {
      const response = seoStore.getSeo(parseInt(id, 10));

      response.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });

      globalState.setRefreshFunction(seoStore.getSeo, parseInt(id, 10));
    } else {
      globalState.setRefreshFunction(seoStore.getSeo, 0);
    }
  }, [id]);

  useEffect(() => {
    setUrl(seo.url);
    setSwitchChecked(!seo.text?.includes('<!-- widget -->'));
    setMetaH1(seo.meta_h1 || '');
    setMetaTitle(seo.meta_title || '');
    setMetaDescription(seo.meta_description || '');
    setMetaKeywords(seo.meta_keywords || '');
    setText(seo.text || '');
  }, [seo]);

  const onUrlChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setUrl(value.currentTarget.value);
    },
    [setFieldsChanged, setUrl]
  );

  const onMetaTitleChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setMetaTitle(value.currentTarget.value);
    },
    [setFieldsChanged, setMetaTitle]
  );

  const onMetaH1Change = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setMetaH1(value.currentTarget.value);
    },
    [setFieldsChanged, setMetaH1]
  );

  const onMetaDescriptionChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setMetaDescription(value.currentTarget.value);
    },
    [setFieldsChanged, setMetaDescription]
  );

  const onMetaKeywordsChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setMetaKeywords(value.currentTarget.value);
    },
    [setFieldsChanged, setMetaKeywords]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  const onEditorChange = useCallback(
    (val: string) => {
      setFieldsChanged(true);
      setText(val);
    },
    [setText, setFieldsChanged]
  );

  const onSeoSaveClick = () => {
    const response = seoStore.editSeo(seo.id, {
      ...seo,
      url,
      meta_h1: metaH1,
      meta_title: metaTitle,
      meta_description: metaDescription,
      meta_keywords: metaKeywords,
      text
    });

    response.then((val) => {
      if (val.isOk) {
        const reloadResp = seoStore.getSeo(parseInt(id || '-1', 10));

        reloadResp.then((newVal) => {
          if (!newVal.isOk) {
            notifyUser(newVal.msg, 'error');
          } else {
            notifyUser('Изменения сохранены', 'success');
            setFieldsChanged(false);
          }
        });
      } else {
        notifyUser('Не удалось сохранить изменения', 'error');
      }
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Tabs
              value={tabPanel}
              onChange={handleChange}
              aria-label="Seo Tabs"
            >
              <Tab
                label="Редактирование"
                id="tab-0"
                aria-controls="tabpanel-0"
              />
            </Tabs>

            {fieldsChanged && (
              <Button
                size="small"
                variant="outlined"
                color="success"
                sx={{ alignSelf: 'center', mr: 2 }}
                onClick={onSeoSaveClick}
              >
                Сохранить изменения
              </Button>
            )}
          </Box>

          <TabPanel value={tabPanel} index={0}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                URL:
              </Typography>
              <TextField
                value={url}
                onChange={onUrlChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                helperText="/catalog/example"
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                H1:
              </Typography>
              <TextField
                multiline
                value={metaH1}
                onChange={onMetaH1Change}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Meta title:
              </Typography>
              <TextField
                multiline
                value={metaTitle}
                onChange={onMetaTitleChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Meta description:
              </Typography>
              <TextField
                value={metaDescription}
                onChange={onMetaDescriptionChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            {/* <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Meta keywords:
              </Typography>
              <TextField
                value={metaKeywords}
                onChange={onMetaKeywordsChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box> */}

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Stack
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
                sx={{ paddingTop: '50px', width: '80%' }}
              >
                <Switch
                  checked={switchChecked}
                  onChange={() => setSwitchChecked((val) => !val)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>
                  {!switchChecked ? 'Режим кода' : 'Визуальный режим'}
                </Typography>
              </Stack>
            </Box>

            {switchChecked ? (
              <Editor
                id="id"
                value={!text ? undefined : text}
                notifyUser={notifyUser}
                onEditorChange={onEditorChange}
              />
            ) : (
              <TextField
                fullWidth
                multiline
                minRows={10}
                value={text || ''}
                onChange={(val) => {
                  setText(val.target.value);
                  setFieldsChanged(true);
                }}
              />
            )}
          </TabPanel>
        </Box>
      )}
    </Box>
  );
};

export default observer(SeoTag);
